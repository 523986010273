<template>
	<div class="dashboard">
		<va-card v-if="(getRole() != 'OPERATOR') && (getRole() != 'RESELLER') && (getRole() != 'HEPI_RESELLER') && (getRole() != 'HEPI_OPERATOR')" align="center" justify="center">
			<h1>Welcome To Cloud OTT UI</h1>
		</va-card>
		<div v-if="(getRole() == 'OPERATOR') || (getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER') || (getRole() == 'HEPI_OPERATOR')">
			<va-card v-if="notoperator" align="center" justify="center">
				<h1>Operator setting not found. Please contact your admin!</h1>
			</va-card>
			<div>
				<div class="ml-2 text-size row">
					<p><b>Hello,</b></p>
					<p class="ml-2" style="color: #1d63b1;"><b>{{username}}</b></p>
				</div>
				<va-card class="mt-3 card-radius">
					<span class="row ml-2" style="margin-top: -1px;">
						<span class="header-size"><b>Credits</b></span>
					</span>
					<div class="row">
						<span class="flex ml-2 md8 text-size"><b>{{amt}}</b></span>
						<span v-if="!moqOutCall && ((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR'))" class="exp"><b>Validity {{validityDate}}</b></span>
						<center>
							<va-button class="credit_btn" v-if="moqOutCall && ((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR'))"
								icon="fa fa-plus" @click.prevent="transactionPage()">Add Credit</va-button>
							<va-button class="credit_btn" style="margin-left: 180px;" v-if="(getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER')" icon="fa fa-plus"
								@click.prevent="transactionPage()">Add Credit</va-button>
						</center>
					</div>
				</va-card>
			</div>
			<div v-if="inoperator && ((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR'))">
				<div class="row row-equal ma-1">
					<div class="flex xl8 xs12">
						<div class="row">
							<div class="flex xs12">
								<div class="row flex">
									<!-- <va-card class="ma-2 card-radius" style="max-width: 47.899%;">
										<div class="row ml-1" style="margin-top: -1px;">
											<va-badge color="#4DA138" style="height: 18px; margin-top: 6px;"></va-badge>
											<span class="font-title"><b>Online Subscribers</b></span>
										</div><br>
										<va-card-content>
											<div class="flex row">
												<va-card class="ma-2 card-radius" style="background: #ddf3e0;">
													<img src="@/assets/online-app.png"
														style="width: 44px; height: 44px;" />
													<div>Application</div>
													<div class="lable-size"><b>{{online_app}}</b></div>
												</va-card>
												<va-card class="ma-2 card-radius" style="background: #ddf3e0;">
													<img src="@/assets/online-stb.png"
														style="width: 44px; height: 44px;" />
													<div>Set Top Box</div>
													<div class="lable-size"><b>{{online_stb}}</b></div>
												</va-card>
											</div>
										</va-card-content>
									</va-card> -->
									<va-card class="card-radius ma-2">
										<div class="font-title"><b>Active Subscribers</b></div><br>
										<va-card-content>
											<div class="row flex">
												<va-card class="ma-2 card-radius" style="background: #dbf2ff;">
													<img src="@/assets/active-app.png"
														style="width: 44px; height: 44px;" />
													<div>Application</div>
													<div class="lable-size"><b>{{active_app}}</b></div>
												</va-card>
												<va-card class="ma-2 card-radius" style="background: #fff0db;">
													<img src="@/assets/active-stb.png"
														style="width: 44px; height: 44px;" />
													<div>STB Boxes</div>
													<div class="lable-size"><b>{{active_stb}}</b></div>
												</va-card>
											</div>
										</va-card-content>
									</va-card>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="flex xs12 md12">
								<va-card class="card-radius">
									<va-card-content>
										<div class="flex xs12 md12">
											<div class="row">
												<span class="flex xs12 md7">
													<div class="font-title"><b>Due Subscribers</b></div>
													<va-chart class="chart chart--donut" :data="donutChartData"
														type="donut" />
													<center><b>Total subscribers - <span
																style="font-size: 20px;">{{this.totalDays}}</span></b>
													</center>
												</span>
												<span class="flex xs12 md5">
													<span class="subcss">
														<span style="margin-left: 7px;">Days</span>
														<span style="margin-right: 40px; margin-left: 45px;">No of
															Subscribers</span>
													</span><br><br>
													<div class="row ml-1">
														<va-badge color="#E91E34" class="badge"></va-badge>
														<span class="day" style="color: #e91e34;"><b>Today</b></span>
														<span class="day" style="margin-left: 80px;"><b>
																{{today}}</b></span><br>
													</div>
													<va-separator />
													<div class="row ml-1">
														<va-badge color="#FFD42B" class="badge"></va-badge>
														<span>5 Days</span>
														<span class="day" style="margin-left: 83px;">{{fiveDays}}</span>
													</div>
													<va-separator />
													<div class="row ml-1">
														<va-badge color="#FF7700" class="badge"></va-badge>
														<span>10 Days</span>
														<span class="day"
															style="margin-left: 75px;">{{tenDays}}</span><br>
													</div>
													<va-separator />
													<div class="row ml-1">
														<va-badge color="#2CB2F2" class="badge"></va-badge>
														<span>20 Days</span>
														<span class="day"
															style="margin-left: 75px;">{{twentyDays}}</span><br>
													</div>
													<va-separator />
													<div class="row ml-1">
														<va-badge color="#4DA138" class="badge"></va-badge>
														<span>30 Days</span>
														<span class="day"
															style="margin-left: 75px;">{{thirtyDays}}</span><br>
													</div>
												</span>
											</div>
										</div>
									</va-card-content>
								</va-card>
							</div>
						</div>
					</div>


					<div class="flex xs12 md4 xl4">
						<va-card stripe stripe-color="info" class="col-md-3 col-sm-4 card-radius mt-2"
							style="height: 651px; overflow: auto;">
							<va-card-title>
								<div class="ml-3 font-title"><b>Bundles</b></div>
							</va-card-title>
							<va-card-content><br>
								<div v-for="item in items" class="mt-2">
									<div v-if="item.count > 0">
										<va-card style="border-radius: 10px; background: #d6f0ff;">
											<div class="row flex xs12">
												<div class="flex xs3">
													<img src="@/assets/bundle.png" style="width: 40px; height: 40px;" />
												</div>
												<div class="flex xs6 mt-2">
													<center>{{item.bundle_name}}</center>
												</div>
												<div class="flex xs3 mt-1">
													<center class="ml-1" style="font-size: 20px;"><b>{{item.count}}</b>
													</center>
												</div>
											</div>
										</va-card>
									</div>
									<va-card v-else-if="item.count == '0'"
										style="background: #f2f2f2; border-radius: 10px;">
										<div class="row flex xs12">
											<div class="flex xs3">
												<img src="@/assets/UnselectBundle.png"
													style="width: 40px; height: 40px;" />
											</div>
											<div class="flex xs6 mt-2">
												<center>{{item.bundle_name}}</center>
											</div>
											<div class="flex xs3 mt-1">
												<center class="ml-1" style="font-size: 20px;"><b>{{item.count}}</b>
												</center>
											</div>
										</div>
									</va-card>
								</div>
							</va-card-content>
						</va-card>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import vueResource from 'vue-resource'
	import config from '../../i18n/en.json'
	Vue.use(vueResource)
	export default {
		name: 'dashboard',
		beforeCreate() {
			var role = Vue.$cookies.get('userRole')
			if (role == 'OPERATOR' || role == 'HEPI_OPERATOR') {
				this.$http.get(config.menu.host + '/dashboard').then(respo => {
					this.inoperator = true
					this.notoperator = false
					this.moqOutCall = false
					this.validityDate = ' : ' + respo.body.start_date.split('T')[0] + ' to ' + respo.body.end_date.split('T')[0]
					var amt = 0;
					this.$http.get(config.menu.host + '/transaction/credit/calculation')
						.then(res => {
							if ((typeof (res.body.object) == 'object') && this.isEmpty(res.body.object)) {
								this.amt = 0
							} else {
								this.amt = (res.body.object).toFixed(2)
								if (this.amt == 0) {
									this.moqOutCall = true
								}
								if (this.amt < 0) {
									this.amt = -(this.amt)
								}
							}
						})
					this.get_dashboard()
				}, error => {
					if (error && error.body) {
						if (error.body == 'No MOQ found') {
							this.amt = 0
							this.inoperator = true
							this.notoperator = false
							this.moqOutCall = true
							this.get_dashboard()
						} else {
							this.notoperator = true
							this.inoperator = false
						}
					}
				})
			} else if (role == 'RESELLER' || role == 'HEPI_RESELLER') {
				this.$http.get(config.menu.host + '/transaction/credit/calculation')
					.then(res => {
						if ((typeof (res.body.object) == 'object') && isEmpty(res.body.object)) {
							this.amt = 0
						} else {
							this.amt = (res.body.object).toFixed(2)
						}
					})
			}
		},
		data() {
			return {
				username: Vue.$cookies.get('first_name'),
				amt: 0.00,
				validityDate: '',
				fiveDays: '',
				tenDays: '',
				thirtyDays: '',
				twentyDays: '',
				today: '',
				donutChartData: '',
				items: [],
				active_stb: '',
				active_app: '',
				online_app: '',
				online_stb: '',
				totalDays: '',
				notoperator: false,
				inoperator: false,
				moqOutCall: false
			}
		},
		methods: {
 isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
},

			getRole() {
				return Vue.$cookies.get('userRole')
			},
			get_dashboard() {
				this.$http.get(config.menu.host + '/dashboard/org_subscription').then(respon => {
					this.fiveDays = respon.body.five
					this.tenDays = respon.body.ten
					this.thirtyDays = respon.body.thirtyth
					this.twentyDays = respon.body.twentyth
					this.today = respon.body.today
					this.totalDays = respon.body.today + respon.body.five + respon.body.thirtyth + respon.body.twentyth + respon.body.ten;
					this.donutChartData = {
						datasets: [{
							backgroundColor: ['#E91E34', '#FFD42B', '#FF7700', '#2CB2F2', '#4DA138'],
							data: [this.today, this.fiveDays, this.tenDays, this.twentyDays, this.thirtyDays],
							hover: { mode: null }
						}]
					}
					this.$http.get(config.menu.host + '/dashboard/bundle_list').then(resp => {
						this.items = resp.body
						this.items.sort(sort_by_count)
						function sort_by_count(a, b) {
							if (a.count > b.count) {
								return -1;
							}
							if (a.count < b.count) {
								return 1;
							}
							return 0;
						}
						this.$http.get(config.menu.host + '/dashboard/active').then(respons => {
							this.active_app = respons.body.app
							this.active_stb = respons.body.stb
							this.$http.get(config.menu.host + '/dashboard/online').then(respo_arr => {
								this.online_app = respo_arr.body.app
								this.online_stb = respo_arr.body.stb
							})
						})
					})
				})
			},
			transactionPage() {
				this.$router.push({ name: 'transaction' })
			}
		}
	}
</script>

<style lang="scss">
  .text-size {
    font-size: 35px;
  }

  .lable-size {
    font-size: 22px;
  }

  .chart {
    height: 200px;
    margin-top: 37px;
  }

  .header-size {
    font-size: 20px;
  }

  .card_set {
    max-width: 62.8909%;
  }

  .exp {
    font-size: 18px;
    background: #fcd6d6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    color: #ff0800;
    height: fit-content;
    padding: 7px;
    margin-left: 100px;
  }

  .subcss {
    font-size: 18px;
    background: #f2f2f2;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: fit-content;
    padding: 4px;
  }

  .badge {
    height: 18px;
    margin-top: 4px;
  }

  .vl {
    border-left: 1px solid #c6c6c6;
    height: 200px;
    margin-top: 70px;
  }

  .card-radius {
    border-radius: 25px;
  }

  .va-chart {
    pointer-events: none;
  }

  .font-title {
    font-size: 19px;
  }

  .day {
    font-size: 17px;
  }

  .credit_btn {
    margin-bottom: 40px;
    font-weight: 700;
  }
</style>
